
import React, { Component } from 'react'
import { Download } from '../components/icon'
import Layout from '../components/Layout'

const getAge = (dateString) =>{
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
	}
	return age;
}

class index extends Component {
  render() {
    return (
		<Layout>
			<section className="content-section-xl">
				<img src="/assets/me.png" className="bg-img" alt=""/>
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h1 className="main-title"> Hi, <br/> I am Frontend Developer</h1>
							<code>
								<pre>
									{
										JSON.stringify( {
											name: "Muhammad Khotib",
											age: getAge("1998-03-10"),
											gender: "Male",
										} , null, 2)
									}
								</pre>
							</code>
							<a style={{color: "#D67D7F",textDecoration: "none"}} className="mt-5 download-icon" href="/Resume-Muhammad-Khotib.pdf">
								<Download size={18} color="#D67D7F" />
								Download Resume
							</a>
						</div>
					</div>
				</div>
			</section>
		</Layout>
    )
  }
}

export default index
